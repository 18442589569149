// Override default variables before the import
$body-bg: #f6f8fa;
$primary: #8d5abc;
$font-family-sans-serif: Poppins;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --bs-body-font-family: 'Poppins';
  --bs-light-rgb: 255,255,255!important;
}
.btn-outline-secondary {
  color: #808080!important;
  border-color: #D1D1D1!important;
  border-width: 2px!important;
  border-radius: 10px!important;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #efefef!important;
}
.btn-outline-primary {
  color: #6F549F!important;
  border-color: #997dcd!important;
  border-width: 2px!important;
  border-radius: 10px!important;
}
.form-switch .form-check-input {
  width: 2.8em!important;
}
.form-floating {
  margin-top: 10px;
}
.form-control {
  background-color: #f6f8fa00!important;
  font-size: 13px!important;

}
.form-floating > label{
  font-size: 13px!important;
  padding: 1.2rem 0.75rem!important;
  color: #000;
}
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label{
  padding: 0.8rem 0.5rem!important;
    color: #8d8d8d!important;
    opacity: 1!important;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.7rem!important;
}
.btn-outline-primary:hover {
  color: #997dcd!important;
  border-color: #997dcd!important;
  border-width: 2px!important;
  border-radius: 10px!important;
  background-color: #8d5abc1a!important;
}
.pagination.pagination-sm {
  -webkit-box-pack: center!important;
  justify-content: center!important;
}
.pagination-sm .page-link {
  padding: 0.4rem 1rem!important;
}
.page-item.active .page-link {
  z-index: 3!important;
  color: #656565!important;
  background-color: #E2E2E2!important;
  border-color: #E2E2E2!important;
}
.btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 11px 16px !important;
  border-radius: 7px !important;
}
.btn-secondary {
  color: #333333!important;
  background-color: #E2E2E2!important;
  border-color: #E2E2E2 !important;
}
.btn-secondary:hover {
  background-color: #cdcdcd !important;
}
.btn-secondary:focus {
  background-color: #cdcdcd !important;
}
.navbar-light{
  box-shadow: 0 3px 5px rgb(57 63 72 / 3%);
}
td{
  padding: 20px!important;
  border-color: #EBEBEB!important;
}
th {
  color: #AFAFAF!important;
  font-weight: 500!important;
  font-size: 12px!important;
}
.table > :not(caption) > * > * {
  color: #ACACAC!important;
  font-weight: 400!important;
}
.select__value-container{
  padding: 0px 8px!important;
  font-size: 13px!important;
}
.select__input-container{
  margin: initial!important;
  padding-bottom: initial!important;
  padding-top: initial!important;
}

input {
    padding: 12px 12px!important;
}
.form-select{
  padding: 12px 12px!important;
}
th {
  text-transform: uppercase!important;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: none!important;
}
.rdw-image-wrapper{
  display: none!important;
}
  .rdw-embedded-wrapper {
    display: none!important;
  }
  .rdw-suggestion-dropdown{
    min-width: 180px!important;
    max-height: 211px!important;
    font-size: 12px!important;
  }
  .tooltip-inner::-webkit-scrollbar {
    width: 12px!important;
  }
  
  .tooltip-inner::-webkit-scrollbar-track {
    background: orange!important;
  }
  
  .tooltip-inner::-webkit-scrollbar-thumb {
    background-color: blue!important; 
    border-radius: 20px!important; 
    border: 3px solid orange!important;
  }
// Tooltip
.tooltip-inner {
    font-size: 12px!important;
    max-width: 300px!important;
    padding: 1rem 1rem!important;
    text-align: center!important;
    background-color: #fff!important;
    font-family: var(--bs-body-font-family)!important;
    text-align: left!important;
    font-weight: 400!important;
    color: #808080!important;
    line-height: 15px!important;
    border-radius: 7px!important;
    opacity: 1!important;
    box-shadow: 0 5px 13px 0 rgb(0 0 0 / 20%) !important;
}
.tooltip.show {
  opacity: 1!important;
}
.tooltip .tooltip-arrow {
  transform: translate(146px, 0px)!important;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px!important;
  border-width: 0.4rem 0.4rem 0!important;
  border-top-color: #fff!important;
  border-bottom-color: #fff!important;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff!important;
}
// Tooltip
.public-DraftStyleDefault-ltr {
  text-align: inherit!important;
}
// Calendario
.react-datepicker__header {
  background-color: #8d5abc !important;
  border-bottom: 1px solid #9257c9!important;
}
.react-datepicker__navigation {
  top: 15px!important;
  background: #7e52a7!important;
  border-radius: 93%!important;
  padding-top: 10px!important;
  padding-left: 7px!important;
  margin: 0px 5px!important;
  height: 32px!important;
  width: 32px!important;
}
.react-datepicker__navigation:hover {
  top: 15px!important;
  background: #7e52a7!important;
  border-radius: 93%!important;
  padding-top: 10px!important;
  padding-left: 7px!important;
  margin: 0px 5px!important;
  height: 32px!important;
  width: 32px!important;
  opacity: 0.6!important;
  transition: 0.2s!important;
}
.react-datepicker__navigation:focus {
  top: 15px!important;
  background: #553572!important;
  border-radius: 93%!important;
  padding-top: 10px!important;
  padding-left: 7px!important;
  margin: 0px 5px!important;
  height: 32px!important;
  width: 32px!important;
  opacity: 0.6!important;
  transition: 0.2s!important;
}
select.react-datepicker__month-select {
  background: #ffffffd9!important;
  border-radius: 4px!important;
  padding: 4px!important;
  border-width: 1px!important;
  color: #333333!important;
  font-family: var(--bs-body-font-family) !important;
  font-size: 11px!important;
  transition: 0.2s!important;
}
select.react-datepicker__month-select:hover {
  background: #ffffff!important;
  border-radius: 4px!important;
  padding: 4px!important;
  border-width: 1px!important;
  color: #333333!important;
  font-family: var(--bs-body-font-family) !important;
  font-size: 11px!important;
  transition: 0.2s!important;
}
select.react-datepicker__year-select {
  background: #ffffffd9!important;
  border-radius: 4px!important;
  padding: 4px!important;
  border-width: 1px!important;
  color: #333333!important;
  font-family: var(--bs-body-font-family) !important;
  font-size: 11px!important;
  transition: 0.2s!important;
}
select.react-datepicker__year-select:hover {
  background: #ffffff!important;
  border-radius: 4px!important;
  padding: 4px!important;
  border-width: 1px!important;
  color: #333333!important;
  font-family: var(--bs-body-font-family) !important;
  font-size: 11px!important;
  transition: 0.2s!important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #fff!important;
  font-weight: 400!important;
  font-size: 0.944rem;
  margin-bottom: 7px;
  font-family: var(--bs-body-font-family) !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #fff!important;
  top: 6px!important;
  width: 10px!important;
  height: 10px!important;
}
.react-datepicker__navigation-icon--previous::before {
  border-color: #fff!important;
  top: 6px!important;
  width: 10px!important;
  left: -8px!important;
  height: 10px!important;
}
.react-datepicker__day-name {
  color: #ffffff!important;
  font-weight: 600!important;
  font-family: var(--bs-body-font-family)!important;
}
.react-datepicker__close-icon::after {
  background-color: #d7d7d7!important;
  color: #3c3c3c!important;
  border-radius: 100%!important;
  padding: 4px 6px!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: rgb(213 213 213) !important;
  color: #333333!important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #d5d5d5!important;
  color: #333333!important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: rgb(195 195 195) !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgb(227 227 227) !important;
  color: #333333!important;
}
// Calendario fim

.btn {
  border-radius: 10px!important;
}
.modal-body {
  font-size: 14px!important;
  font-weight: 400!important;
  color: #333333!important;
}
tbody>tr>:nth-child(1){
  color: #333333!important;
  font-weight: 500!important;
 }

html {
  font-family: 'Poppins', var(--font-family-sans-serif);
}
body {
  font-family: 'Poppins', var(--font-family-sans-serif);
}

@import '~bootstrap/scss/bootstrap.scss';
// Import Bootstrap and its default variables

